import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const FormSection = ({ title, children }) => {
    return (
        <div className="c-form-section">
            {title && <h3 className="c-form-section__title">{title}</h3>}

            {children}
        </div>
    )
}

FormSection.propTypes = {
    title: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FormSection.defaultProps = {
    children: '<p>Hidden fields content goes here</p>'
}

export default FormSection
